import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout context={{
    type: 'generic',
    title: 'Page Not Found',
    locale: 'en',
    localizedUrls: {
      en: '/404'
    },
    description: '',
  }}>
    <div className="relative flex items-center justify-center h-[720px] w-full">
      <div className="absolute w-full h-[720px]">
        <img className="w-full h-[720px] object-cover z-0" src="/404.jpg" alt="" />
      </div>
      <div className="bg-white rounded-xl shadow-lg z-10 p-12 flex flex-col justify-center items-center space-y-16">
        <h1 className="text-4xl text-mango z-10">The page you are looking for cannot be found</h1>
        <Link to="/">
          <a className="bg-coconut px-8 py-4 rounded-lg text-white text-lg">Back to Home</a>
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
